.resume__options {
    width: 100%;
}

.resume__option > a {
	text-decoration: none;
	color: white;
}

li {
    color: white;
    padding: 0 1rem;
    margin: 1rem 0;
}

.resume__option:hover {
    background-color: white;
    color: black;
}
